import PlainBoard from "./plainBoard";
import {LocatedConstraint} from "../types";
import GridLocation from "./gridLocation";
import {solve} from "./search";
import {shuffle} from "../utils";

export class ConstraintMinimizer {
    private constrainedLocs: Map<GridLocation, LocatedConstraint>;
    private eliminatedConstraints: LocatedConstraint[];

    constructor(private readonly plainBoard: PlainBoard, allConstraints: LocatedConstraint[]) {
        this.constrainedLocs = new Map<GridLocation, LocatedConstraint>();
        this.eliminatedConstraints = new Array<LocatedConstraint>();

        allConstraints.forEach(lc => this.constrainedLocs.set(lc.loc, lc));
        this.tryEliminateConstrainedLoc = this.tryEliminateConstrainedLoc.bind(this);
    }


    public minimize(returnedConstraintProportion: number = 0.0): LocatedConstraint[] {

        const initialConstraints = Array.from(this.constrainedLocs.keys());
        shuffle(initialConstraints).forEach(this.tryEliminateConstrainedLoc);

        const returnedConstraintCount = Math.min(
            Math.ceil(returnedConstraintProportion * this.eliminatedConstraints.length),
            this.eliminatedConstraints.length);
        const returnableConstraints = this.eliminatedConstraints;
        const returnedConstraints = shuffle(returnableConstraints).slice(0, returnedConstraintCount);
        console.log(`Returning ${returnedConstraints.length} constraints in deference to difficulty level.`)
        const constraints = Array.from(this.constrainedLocs.values()).concat(returnedConstraints);

        return constraints;
    }

    private tryEliminateConstrainedLoc(loc: GridLocation) {
        const constraints = Array.from(this.constrainedLocs.values()).filter(c => !c.loc.equals(loc));
        const result = solve(this.plainBoard, constraints);

        if (result === "nonunique" || result === 'unsolvable') return;

        // If there was a unique solution, it turns out we don't need this constraint and so we ditch it.
        this.eliminatedConstraints.push(this.constrainedLocs.get(loc)!);
        this.constrainedLocs.delete(loc);
    }
}