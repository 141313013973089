import React from 'react';
import SkyLight from 'react-skylight';

class RulesPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    var myBigGreenDialog = {
      backgroundColor: '#77C76E',
      color: '#ffffff',
      width: '70%',
      height: '80%',
      marginTop: '-35%',
      marginLeft: '-35%',
    };

    return (
      <>
        <button className={'button game-button__rules'} onClick={() => this.customDialog.show()}>Rules</button>
        <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={ref => this.customDialog = ref}
                  title="Light Up Rules">
          <ul>

            <li>
              Put lights around so everything is lit up.
            </li>
            <li>
              Lights can't shine on one another.
            </li>
            <li>
              Numbers indicate how many lights are next to them.
            </li>

          </ul>

          <p>This is a classic Nikoli puzzle format. <a
            href='https://en.wikipedia.org/wiki/Light_Up_(puzzle)'>Wikipedia</a>.</p>
        </SkyLight>
      </>
    )
  }
}

export default RulesPopup;
