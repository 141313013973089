import GridLocation from "./logic/gridLocation";
import {GameOptions, GameSpec} from "./types";
import {PersistantBoolean, PersistantNumber} from "./persistantValue";

export class Directions {
    public static readonly Up = new GridLocation(-1, 0);
    public static readonly Down = new GridLocation(1, 0);
    public static readonly Left = new GridLocation(0, -1);
    public static readonly Right = new GridLocation(0, 1);
    public static readonly allDirections = [Directions.Up, Directions.Down, Directions.Left, Directions.Right];
}

class Constants {
    static readonly simpleBoard: GameSpec = {
        size: {height: 7, width: 7},
        constraints: [
            {loc: new GridLocation(0, 1), litNeighbours: 2},
            {loc: new GridLocation(0, 3), litNeighbours: 3},
            {loc: new GridLocation(1, 6), litNeighbours: 0},
            {loc: new GridLocation(6, 5), litNeighbours: 2},
        ],
        walls: [
            new GridLocation(0, 1),
            new GridLocation(0, 3),
            new GridLocation(1, 6),
            new GridLocation(3, 0),
            new GridLocation(3, 6),
            new GridLocation(5, 0),
            new GridLocation(6, 3),
            new GridLocation(6, 5),
        ],
    };

    static readonly defaultGameOptions: GameOptions = {
        size: {height: 7, width: 7},
        displayOptions: {},
        difficulty: "hard",
        symmetricBoard: true,
        quickStart: false,
        sameSolutionAsPreviousGame: false,
        constraintMinimizationAttempts: 6,
    }

    static readonly squareBoardPV = new PersistantBoolean('squareBoard', true);
    static readonly quickStartPV = new PersistantBoolean('quickStart', Constants.defaultGameOptions.quickStart);
    static readonly sizePV = new PersistantNumber('size', Constants.defaultGameOptions.size.height);
    static readonly heightPV = new PersistantNumber('height', Constants.defaultGameOptions.size.height);
    static readonly widthPV = new PersistantNumber('width', Constants.defaultGameOptions.size.width);


    static minWallDensity: number = .05;
    static maxWallDensity: number = .20;
    static minSelectableGridSize: number = 6;
    static maxSelectableGridSize: number = 20;

    // This is the blank braile character
    static blankCharacter: string = '⠀';

}

export default Constants;

