import React, {Component} from 'react';
import GridLocation from "../logic/gridLocation";
import {DisplayOptions, TileState} from "../types";
import '../css/gameTile.css';
import Constants from "../constants";

interface GameTileProps {
    displayOptions: DisplayOptions,
    tileState: TileState,
    tileClickFn: (loc: GridLocation) => void,
}

interface GameTileState {
}

class GameTile extends Component<GameTileProps, GameTileState> {
    clickFn = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.props.tileClickFn(this.props.tileState.loc);
    }

    displayedText = () => {
        const ts = this.props.tileState;
        if (ts.constraint) return ts.constraint.requiredLitNeighbours.toString();
        if (ts.bulbState === 'prohibited') return 'x';
        return Constants.blankCharacter;
    }

    render() {
        const ts = this.props.tileState;
        return (
            <div className={this.cssClasses()} key={ts.loc.toString()}>
                <div className={'gametile__number'} onClick={this.clickFn}>
                    {this.displayedText()}
                </div>

            </div>
        );
    }

    private cssClasses() {
        let ts = this.props.tileState;
        let ret = [
            'gametile',
            `gametile--${ts.bulbState}`,
            ts.litBy.size === 0 ? 'gametile--unlit' : 'gametile--lit',
            ts.isWall ? 'gametile--wall' : 'gametile--floor',
            ts.constraint ? 'gametile--has-constraint' : 'gametile--has-no-constraint',
        ];
        if (ts.bulbState === 'bulb' && ts.litBy.size > 1) ret.push('gametile--overlit-bulb');
        if (ts.constraint && ts.constraint?.satisfactionState === 'too-many')
            ret.push('gametile--oversatisfied-constraint');
        if (ts.constraint && ts.constraint?.satisfactionState === 'satisfied')
            ret.push('gametile--satisfied-constraint');


        return ret.join(' ');
    }
}

export default GameTile;