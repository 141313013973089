import GridLocation from "./gridLocation";
import {BulbState, GameMove, GameSpec, GridSize, TileState} from "../types";
import PlainBoard from "./plainBoard";
import {Directions} from "../constants";
import {cycle} from "../utils";


class Game {
    public onBoard: (loc: GridLocation) => boolean;
    public tileStates: TileState[][];
    private plainBoard: PlainBoard;
    private moveStack: GameMove[];
    private totalLit: number;
    private floorSpace: number;
    private constrainedLocs: GridLocation[];

    constructor(public readonly gameSpec: GameSpec) {
        this.moveStack = [];
        this.tileStates = new Array(this.size.height);
        this.plainBoard = new PlainBoard(gameSpec.size, gameSpec.walls);
        this.onBoard = this.plainBoard.onBoard;
        this.totalLit = 0;
        this.floorSpace = this.size.width * this.size.height;
        this.constrainedLocs = [];


        for (let i = 0; i < this.size.height; i += 1) {
            this.tileStates[i] = new Array(this.size.width);
            for (let j = 0; j < this.size.width; j += 1) {
                const loc = new GridLocation(i, j);
                this.tileStates[i][j] = {
                    loc: loc,
                    bulbState: 'empty',
                    isWall: false,
                    litBy: new Set<string>(),
                }
            }
        }

        gameSpec.walls.forEach(loc => {
            const tile = this.getTile(loc);
            tile.isWall = true;
            tile.bulbState = 'empty';
            this.floorSpace -= 1;
        });
        gameSpec.constraints.forEach(c => {
            const loc = c.loc;
            const tile = this.getTile(loc);
            if (!tile.isWall) throw new Error(`Constrained tile should be a wall.`);
            tile.constraint = {
                requiredLitNeighbours: c.litNeighbours,
                currentlyLitNeighbours: 0,
                satisfactionState: c.litNeighbours === 0 ? "satisfied" : 'not-enough',
            };
            this.constrainedLocs.push(loc);
        });


    }

    public get size(): GridSize {
        return this.gameSpec.size;
    }

    public undo() {
        if (this.moveStack.length === 0) {
            console.log(`No move to undo.`);
            return;
        }
        const lastmove = this.moveStack.pop()!;
        this.setLighting(lastmove.loc, lastmove.oldState, false);
    }

    public setLighting(loc: GridLocation, newBulbState: BulbState, addToMoveStack: boolean = true) {
        if (!this.onBoard(loc)) throw new Error(`Location off board ${loc}`);
        const tile = this.getTile(loc);
        if (tile.isWall) throw new Error(`You shouldn't be trying to change the bulb state of a wall.`)
        const oldState = tile.bulbState;
        if (oldState === newBulbState) return;

        tile.bulbState = newBulbState;

        if (oldState === 'bulb' && newBulbState !== 'bulb') {
            this.plainBoard.tilesLitBy(loc).forEach(nloc => {
                let litBy = this.getTile(nloc).litBy;
                litBy.delete(loc.toString());
                if (litBy.size === 0) this.totalLit -= 1;
            });
        }
        if (newBulbState === 'bulb' && oldState !== 'bulb') {
            this.plainBoard.tilesLitBy(loc).forEach(nloc => {
                let litBy = this.getTile(nloc).litBy;
                const wasLit = litBy.size > 0;
                litBy.add(loc.toString());
                if (!wasLit) this.totalLit += 1;
            });
        }

        Directions.allDirections.forEach(dir => this.reevaluateConstraint(loc.withMove(dir)));

        if (addToMoveStack)
            this.moveStack.push({loc, oldState, newBulbState,});
    }

    public getTile(loc: GridLocation) {
        if (!this.onBoard(loc)) throw new Error(`Location off board ${loc}`);
        return this.tileStates[loc.row][loc.col];
    }

    public isCompleted() {
        const entireFloorLit = this.totalLit === this.floorSpace;
        if (!entireFloorLit) return false;

        const bulbTileStates = this.tileStates.flat().filter(ts => ts.bulbState === 'bulb');
        const allBulbsSoloLit = bulbTileStates.every(ts => ts.litBy.size === 1);
        const constraintsSatisfied = this.constrainedLocs.map(cloc => this.getTile(cloc))
            .every(ts => ts.constraint?.satisfactionState === 'satisfied');

        return entireFloorLit && allBulbsSoloLit && constraintsSatisfied;
    }

    public toggleCell(loc: GridLocation) {
        if (!this.onBoard(loc)) return;
        const tile = this.getTile(loc);
        if (tile.isWall) return;
        this.setLighting(loc, cycle(tile.bulbState))
    }

    public markOffAllNeighboursOfZero() {
        this.constrainedLocs.forEach(loc => {
            const t = this.getTile(loc);
            if (t.constraint?.requiredLitNeighbours === 0) {
                Directions.allDirections.forEach(d => {
                    const nloc = loc.withMove(d);
                    if (this.onBoard(nloc) && !this.getTile(nloc).isWall) {
                        this.setLighting(nloc, "prohibited", false);
                    }
                })

            }
        })
    }

    private reevaluateConstraint(loc: GridLocation) {
        if (!this.onBoard(loc)) return;

        const tile = this.getTile(loc);
        if (!tile.constraint) return;

        let bulbCount = 0;
        Directions.allDirections.map(d => loc.withMove(d)).filter(this.onBoard).forEach(loc => {
            if (this.getTile(loc).bulbState === 'bulb') bulbCount += 1;
        });

        tile.constraint.currentlyLitNeighbours = bulbCount;
        if (bulbCount === tile.constraint.requiredLitNeighbours)
            tile.constraint.satisfactionState = 'satisfied';
        if (bulbCount < tile.constraint.requiredLitNeighbours)
            tile.constraint.satisfactionState = 'not-enough';
        if (bulbCount > tile.constraint.requiredLitNeighbours)
            tile.constraint.satisfactionState = 'too-many';

    }
}

export default Game;