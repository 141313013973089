import React, {Component} from 'react';
import './App.css';
import {DisplayOptions, GameOptions} from "./types";
import Game from "./logic/game";
import {findNewBoard} from "./logic/search";
import GameBoard from "./components/GameBoard";
import GameHeader from "./components/gameHeader";

interface AppProps {
}

interface AppState {
  game?: Game,
  displayOptions?: DisplayOptions,
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      game: undefined,
      displayOptions: {},
    }
  }

  newGame = (options: GameOptions) => {
    console.log('Starting new game.');
    let game = new Game(findNewBoard(options));
    if (options.quickStart) {
      game.markOffAllNeighboursOfZero();
    }
    this.setState({
      game: game,
      displayOptions: options.displayOptions,
    });
  }

  undo = () => {
    if (this.state.game) {
      console.log('Trying to undo previous move.');
      this.state.game.undo();
      this.forceUpdate();
    }
  }

  render() {
    return (
        <div className="app">
          <GameHeader
              startFn={this.newGame}
              undoFn={this.undo}
              gameOn={!!this.state.game}
          />
          {this.state.game && this.state.displayOptions ?
              <GameBoard
                  game={this.state.game}
                  displayOptions={this.state.displayOptions}
              /> : <></>}
        </div>
    );
  }
}

export default App;
