import {GameOptions, GameSpec, LocatedConstraint, RandomBoardOptions} from "../types";
import Constants from "../constants";
import PlainBoard from "./plainBoard";
import {allGridLocations} from "../utils";
import GridLocation from "./gridLocation";
import {ConstraintMinimizer} from "./constraintMinimizer";

var Logic = require('logic-solver');


function locatedConstraintToLogicConstraint(plainBoard: PlainBoard, locatedConstraint: LocatedConstraint): any {
    const tot = Logic.constantBits(locatedConstraint.litNeighbours);
    const neighbourSum = Logic.sum(plainBoard.neighbouringFloorTiles(locatedConstraint.loc).map(l => l.toString()));
    return Logic.equalBits(tot, neighbourSum);
}

function tryGetPrunedConstraints(plainBoard: PlainBoard,
                                 lightSources: GridLocation[],
                                 minimizeAttempts: number): LocatedConstraint[] | undefined {
    const allConstraints = plainBoard.getConstraintsGivenLights(lightSources);
    const soln = solve(plainBoard, allConstraints);
    if (soln === 'unsolvable' || soln === 'nonunique') {
        return undefined;
    }

    const cm = new ConstraintMinimizer(plainBoard, allConstraints);
    let bestConstraintSize = Number.MAX_VALUE;
    let bestConstraints: LocatedConstraint[];
    for (let i = 0; i < minimizeAttempts; i += 1) {
        let constraints = cm.minimize();
        if (constraints.length < bestConstraintSize) {
            bestConstraintSize = constraints.length;
            bestConstraints = constraints;
        }
    }

    return bestConstraints!;
}

export function findNewBoard(options: GameOptions): GameSpec {
    const t0 = performance.now();

    const randomBoardOptions: RandomBoardOptions = {
        size: options.size,
        rotationallySymmetric: options.symmetricBoard,
        minWallDensity: Constants.minWallDensity,
        maxWallDensity: Constants.maxWallDensity,
    };

    const attemptsMax = 2000;
    let attemptsMade = 0;
    let foundBoard: GameSpec | undefined = undefined;
    while (!foundBoard && attemptsMade < attemptsMax) {
        // for (let i = 0; i < attemptsMax; i += 1) {
        attemptsMade += 1;
        const plainBoard = PlainBoard.randomBoard(randomBoardOptions);

        const solver = new Logic.Solver();
        solver.require(plainBoard.lightingLogicConstraints());

        let soln = solver.solve();
        if (!soln) {
            console.error(`Couldn't find even one solution to the board.`);
            continue;
        }

        let lightSources = soln.getTrueVars().map((s: string) => GridLocation.fromString(s));
        let constraints = tryGetPrunedConstraints(plainBoard, lightSources, options.constraintMinimizationAttempts);

        if (constraints !== undefined) {
            foundBoard = {
                size: options.size,
                constraints: constraints,
                walls: allGridLocations(options.size).filter(loc => plainBoard.isWall(loc)),
            }
        }
    }

    const t1 = performance.now();
    const searchTimeMs = Math.floor(t1 - t0);
    console.log(`Took ${searchTimeMs}ms to find a board, in ${attemptsMade} attempts.`);

    return foundBoard || Constants.simpleBoard;
}

export function solve(plainBoard: PlainBoard, constraints: LocatedConstraint[]): GridLocation[] | 'unsolvable' | 'nonunique' {
    const solver = new Logic.Solver();
    solver.require(plainBoard.lightingLogicConstraints());
    constraints.forEach(c => solver.require(locatedConstraintToLogicConstraint(plainBoard, c)));

    const soln1 = solver.solve();
    if (!soln1) return 'unsolvable';

    solver.forbid(soln1.getFormula());
    const soln2 = solver.solve();
    if (soln2) return 'nonunique';

    return soln1.getTrueVars().map((s: string) => GridLocation.fromString(s));
}
