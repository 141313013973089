import {GridSize} from "../types";

var Logic = require('logic-solver');

class GridLocation {

    private static boardLocMatcher = new RegExp(`^([0-9]+),([0-9]+)$`);

    constructor(public row: number, public col: number) {
    }

    public static fromString(str: string): GridLocation {
        let test = GridLocation.boardLocMatcher.exec(str);
        if (test) {
            return new GridLocation(+test[1], +test[2]);
        }
        throw new Error(`Unable to parse GridLocation ${str}`);
    }

    static random(size: GridSize): GridLocation {
        const row = Math.floor(size.height * Math.random());
        const col = Math.floor(size.width * Math.random());
        return new GridLocation(row, col);
    }

    rotateAround(rotationalCenter: GridLocation): GridLocation {
        const movedPt = new GridLocation(this.row - rotationalCenter.row, this.col - rotationalCenter.col);
        const rotPt = new GridLocation(-movedPt.col, movedPt.row);

        return rotPt.withMove(rotationalCenter);
    }

    public copy() {
        return new GridLocation(this.row, this.col);
    }

    public withMove(direction: GridLocation): GridLocation {
        return new GridLocation(this.row + direction.row, this.col + direction.col);
    }

    public toString(): string {
        return `${this.row},${this.col}`;
    }

    public onBoard(size: GridSize) {
        return this.col >= 0 && this.row >= 0 && this.col < size.width && this.row < size.height;
    }

    equals(loc: GridLocation) {
        return this.row === loc.row && this.col === loc.col;
    }
}

export default GridLocation;