import GridLocation from "./gridLocation";
import {GridSize, LocatedConstraint, RandomBoardOptions} from "../types";
import {Directions} from "../constants";
import {allGridLocations} from "../utils";

var Logic = require('logic-solver');

class PlainBoard {
    private walls: boolean[][];
    private lightableNeighbours: GridLocation[][][];

    constructor(public readonly size: GridSize, wallLocations: GridLocation[]) {
        this._wallCount = 0;
        this.walls = new Array(this.size.height);
        for (let i = 0; i < this.size.height; i += 1) {
            this.walls[i] = new Array(this.size.width);
            for (let j = 0; j < this.size.width; j += 1) {
                const loc = new GridLocation(i, j);
                this.walls[i][j] = wallLocations.some(wloc => wloc.equals(loc));
                if (this.walls[i][j]) this._wallCount += 1;
            }
        }

        this.lightableNeighbours = new Array(this.size.height);
        this.initializeLightableNeighbours();

        this.onBoard = this.onBoard.bind(this);
        this.isWall = this.isWall.bind(this);
    }

    private _wallCount: number;

    public get wallCount(): number {
        return this._wallCount;
    }

    private _segments?: GridLocation[][];

    public get segments(): GridLocation[][] {
        if (this._segments) return this._segments;
        const ret = [];
        // Scan the rows.
        for (let i = 0; i < this.size.height; i += 1) {
            let cur = false;
            let segment = [];
            for (let j = 0; j < this.size.width; j += 1) {
                const loc = new GridLocation(i, j);
                cur = this.walls[i][j];

                if (!cur) {
                    segment.push(loc);
                }
                if (cur) {
                    if (segment.length > 0) ret.push(segment);
                    segment = [];
                }
            }
            if (segment.length > 0) ret.push(segment);
        }
        // Scan the columns
        for (let j = 0; j < this.size.width; j += 1) {
            let cur = false;
            let segment = [];
            for (let i = 0; i < this.size.height; i += 1) {
                const loc = new GridLocation(i, j);
                cur = this.walls[i][j];

                if (!cur) {
                    segment.push(loc);
                }
                if (cur) {
                    if (segment.length > 0) ret.push(segment);
                    segment = [];
                }
            }
            if (segment.length > 0) ret.push(segment);
        }

        this._segments = ret;
        return this._segments;
    }

    public static randomBoard(options: RandomBoardOptions): PlainBoard {
        const {size} = options;
        const wallProportion = options.minWallDensity + Math.random() * (options.maxWallDensity - options.minWallDensity);
        const totalLocationCount = size.width * size.height;
        const walls = new Set<string>();
        if (!options.rotationallySymmetric) {
            while (walls.size / totalLocationCount < wallProportion) {
                walls.add(GridLocation.random(size).toString());
            }
            return new PlainBoard(size, Array.from(walls).map(GridLocation.fromString));
        } else {
            const smallBoard = PlainBoard.randomBoard({
                ...options,
                size: {
                    height: Math.ceil(size.height / 2),
                    width: Math.floor(size.width / 2),
                },
                rotationallySymmetric: false,
            });
            const smallWalls = allGridLocations(smallBoard.size).filter(loc => smallBoard.isWall(loc));
            const rotationalCenter = new GridLocation(size.height / 2 - .5, size.width / 2 - .5);
            const rots = smallWalls.map(loc => loc.rotateAround(rotationalCenter));
            const rots2 = rots.map(loc => loc.rotateAround(rotationalCenter));
            const rots3 = rots2.map(loc => loc.rotateAround(rotationalCenter));

            return new PlainBoard(size, [...smallWalls, ...rots, ...rots2, ...rots3])
        }
    }

    public onBoard(loc: GridLocation) {
        return loc.row >= 0 && loc.row < this.size.height && loc.col >= 0 && loc.col < this.size.width;
    }

    public neighbouringFloorTiles(loc: GridLocation): GridLocation[] {
        return Directions.allDirections.map(d => loc.withMove(d))
            .filter(loc => this.onBoard(loc) && !this.isWall(loc));
    }

    public isWall(loc: GridLocation): boolean {
        return this.onBoard(loc) && this.walls[loc.row][loc.col];
    }

    /**
     * Get all of our neighbours up to the edge of the board or a wall.
     */
    public tilesLitBy(loc: GridLocation): GridLocation[] {
        if (!this.onBoard(loc)) return [];
        return this.lightableNeighbours[loc.row][loc.col]!;
    }

    /**
     * Logical constraints saying that every location has a light source and no light sources see one another.
     */
    public lightingLogicConstraints() {
        let floorSpace = allGridLocations(this.size).filter(loc => !this.isWall(loc));
        const allLitConstraint = Logic.and(
            floorSpace.map(loc => this.tilesLitBy(loc).map(nloc => nloc.toString()))
                .map(locs => Logic.or(locs)));
        const noLightsCanSeeAnotherLight =
            Logic.and(this.segments.map(locs => Logic.atMostOne(locs.map(loc => loc.toString()))));
        return Logic.and(allLitConstraint, noLightsCanSeeAnotherLight);
    }

    public getConstraintsGivenLights(lightSources: GridLocation[]): LocatedConstraint[] {
        const wallLocs = allGridLocations(this.size).filter(loc => this.isWall(loc));
        const locatedConstraints = wallLocs.map(loc => {
            const litNeighbours = Directions.allDirections.map(dd => loc.withMove(dd))
                .filter(nloc => lightSources.some(ls => ls.equals(nloc))).length;
            return {
                loc,
                litNeighbours
            } as LocatedConstraint;
        });
        return locatedConstraints;
    }

    private initializeLightableNeighbours() {
        for (let i = 0; i < this.size.height; i += 1) {
            this.lightableNeighbours[i] = new Array(this.size.width);
            for (let j = 0; j < this.size.width; j += 1) {
                const loc = new GridLocation(i, j);

                if (this.isWall(loc)) {
                    this.lightableNeighbours[i][j] = [];
                    continue;
                }

                // Calculate the neighbours.
                let litNeighbours = new Array<GridLocation>();
                litNeighbours.push(loc); // You light your own tile.
                Directions.allDirections.forEach(direction => {
                    let curloc = loc.withMove(direction);
                    while (this.onBoard(curloc) && !this.isWall(curloc)) {
                        litNeighbours.push(curloc);
                        curloc = curloc.withMove(direction);
                    }
                });

                this.lightableNeighbours[i][j] = litNeighbours;
            }
        }
    }
}

export default PlainBoard;