import React from 'react';
import SkyLight from 'react-skylight';
import Constants from "../constants";
import {inclusiveIntList} from "../utils";

class OptionsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: Constants.sizePV.getString(),
      height: Constants.heightPV.getString(),
      width: Constants.widthPV.getString(),
      squareBoard: Constants.squareBoardPV.getValue(),
      symmetricBoard: Constants.defaultGameOptions.symmetricBoard,
      quickStart: Constants.quickStartPV.getValue(),
      sameSolutionAsPreviousGame: Constants.defaultGameOptions.sameSolutionAsPreviousGame,
      difficulty: Constants.defaultGameOptions.difficulty,
      constraintMinimizationAttempts: Constants.defaultGameOptions.constraintMinimizationAttempts,
    }
  }

  setSelectOptionFn(pv) {
    return (event) => {
      const optionString = event.target.value;
      // console.log(`saw ${optionString}`)
      // @ts-ignore
      pv.setValue(optionString);
      this.setState({[pv.name]: optionString});
    }
  }

  setDifficulty = (event) => {
    const value = event.target.value;
    this.setState({difficulty: value});
  }

  setSquare = (event) => {
    const checked = event.target.checked;
    Constants.squareBoardPV.setValue(checked);
    this.setState({squareBoard: checked});
  }

  setSymmetric = (event) => {
    const checked = event.target.checked;
    this.setState({symmetricBoard: checked});
  }

  setQuickStart = (event) => {
    const checked = event.target.checked;
    Constants.quickStartPV.setValue(checked);
    this.setState({quickStart: checked});
  }

  setSameSolutionAsPreviousGame = (event) => {
    const checked = event.target.checked;
    this.setState({sameSolutionAsPreviousGame: checked});
  }

  getGameOptions() {
    const size =
      Constants.squareBoardPV.getValue() ? {
          height: Constants.sizePV.getValue(),
          width: Constants.sizePV.getValue(),
        } :
        {
          height: Constants.heightPV.getValue(),
          width: Constants.widthPV.getValue(),
        };
    console.log(size)
    const {sameSolutionAsPreviousGame, difficulty, symmetricBoard, quickStart, constraintMinimizationAttempts} = this.state;
    const displayOptions = {};

    return {
      ...Constants.defaultGameOptions,
      size,
      sameSolutionAsPreviousGame,
      difficulty,
      symmetricBoard,
      quickStart,
      displayOptions,
      constraintMinimizationAttempts,
    };
  }

  render() {

    var myBigGreenDialog = {
      backgroundColor: '#77C76E',
      color: '#ffffff',
      width: '70%',
      height: '80%',
      marginTop: '-35%',
      marginLeft: '-35%',
    };

    return (<>
        <button className={'button game-button__options'} onClick={() => this.customDialog.show()}>Options</button>

        <SkyLight dialogStyles={myBigGreenDialog} hideOnOverlayClicked ref={ref => this.customDialog = ref}
                  title="Light Up Options">

          {/*<label>*/}
          {/*  <h2>Difficulty</h2>*/}
          {/*  <select name="difficulty" defaultValue='hard' onChange={this.setDifficulty}>*/}
          {/*    <option value="easy">Easy</option>*/}
          {/*    <option value="medium">Medium</option>*/}
          {/*    <option value="hard">Hard</option>*/}
          {/*  </select>*/}
          {/*  <br/>*/}
          {/*  In hard difficulty, the hints provided are minimal and every one is needed to find the solution. In the*/}
          {/*  others, some superfluous information is provided, making it a little easier to solve.*/}
          {/*</label>*/}

          <br/>

          <label>
            <input type="checkbox" checked={this.state.squareBoard} onChange={this.setSquare}/>
            Square Board?
          </label>

          <div className={'stack'}>
            {this.state.squareBoard ?
              <label>
                Size:&nbsp;
                <select name='size'
                        value={this.state.size}
                        onChange={this.setSelectOptionFn(Constants.sizePV)}>
                  {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                    <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                  )}
                </select>

              </label> :
              <>
                <label>
                  Height:&nbsp;
                  <select name='height'
                          value={this.state.height}
                          onChange={this.setSelectOptionFn(Constants.heightPV)}>
                    {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                      <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                    )}
                  </select>
                </label>
                <label>
                  Width:&nbsp;
                  <select name='width'
                          value={this.state.width}
                          onChange={this.setSelectOptionFn(Constants.widthPV)}>
                    {inclusiveIntList(Constants.minSelectableGridSize, Constants.maxSelectableGridSize).map(s =>
                      <option value={s.toString()} key={s.toString()}>{s.toString()}</option>
                    )}
                  </select>
                </label>
              </>
            }
          </div>

          <br/>

          <h2>Puzzle Generation Rules</h2>

          {/*<br/>*/}
          {/*<label>*/}
          {/*  <input type="checkbox" checked={this.state.sameSolutionAsPreviousGame}*/}
          {/*         onChange={this.setSameSolutionAsPreviousGame}/>*/}
          {/*  Same puzzle as previous, but with different hints.*/}
          {/*</label>*/}

          <br/>
          <label>
            <input type="checkbox" checked={this.state.symmetricBoard}
                   onChange={this.setSymmetric}/>
            Should the generated board by rotationally symmetric?
          </label>

          <br/>
          <label>
            <input type="checkbox" checked={this.state.quickStart}
                   onChange={this.setQuickStart}/>
            Should we save you a little time by x'ing off all the neighbours of zero constraints?
          </label>

          <h2>Display Options</h2>

        </SkyLight>
      </>
    )
  }
}

export default OptionsPopup;
