export class PersistantNumber {
    private currentValue: number;
    private key: string;

    constructor(public readonly name: string, public readonly defaultValue: number) {
        this.currentValue = defaultValue;
        this.key = `PersistantNumber ${this.name}`;
        this.tryGetValue();
    }

    public getValue(): number {
        this.tryGetValue();
        return this.currentValue;
    }

    public getString(): string {
        this.tryGetValue();
        return this.currentValue.toString();
    }

    public setValue(value: number | string) {
        if (typeof value === 'string') value = +value;
        this.currentValue = value;
        localStorage.setItem(this.key, value.toString());

        // console.log(`SetValue ${value}`);
    }

    private tryGetValue() {
        const ret = localStorage.getItem(this.key);
        if (ret) {
            this.currentValue = +ret;
        }
    }


}

export class PersistantBoolean {
    private currentValue: boolean;
    private key: string;

    constructor(public readonly name: string, public readonly defaultValue: boolean) {
        this.currentValue = defaultValue;
        this.key = `PersistantBoolean ${this.name}`;
        this.tryGetValue();
    }

    public getValue(): boolean {
        this.tryGetValue();
        return this.currentValue;
    }

    public getString(): string {
        this.tryGetValue();
        return this.currentValue.toString();
    }

    public setValue(value: boolean | string) {
        if (typeof value === 'string') value = !!value;
        this.currentValue = value;
        localStorage.setItem(this.key, value.toString());

        console.log(`SetValue ${value}`);
    }

    private tryGetValue() {
        const ret = localStorage.getItem(this.key);
        if (ret) {
            this.currentValue = !!JSON.parse(ret);
        }
    }


}