import React, {Component, CSSProperties} from 'react';
import Game from "../logic/game";
import {DisplayOptions} from "../types";
import GridLocation from "../logic/gridLocation";
import GameTile from "./gameTile";
import '../css/gameBoard.css';

interface GameBoardProps {
    game: Game,
    displayOptions: DisplayOptions,
}

interface GameBoardState {
    width: number,
    height: number,
}

class GameBoard extends Component<GameBoardProps, GameBoardState> {
    constructor(props: GameBoardProps) {
        super(props);
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
    }

    tileClickFn = (loc: GridLocation) => {
        console.log(`Click seen to ${loc.row}/${loc.col}`);
        this.props.game.toggleCell(loc);
        this.forceUpdate();
    }

    render() {
        const game = this.props.game;
        const style = {
            '--rows': this.props.game.size.height,
            '--cols': this.props.game.size.width,
            '--cell-sizepx': this.getMaxCellSize(),
        } as CSSProperties;

        return (
            <div className='gameboard__outer' style={style}>
                <div className={this.cssClasses()}>
                    {game.tileStates.map(row => <>{row.map(tile =>
                        <GameTile tileState={tile}
                                  displayOptions={this.props.displayOptions}
                                  tileClickFn={this.tileClickFn}
                                  key={tile.loc.toString()}
                        />
                    )}</>)}

                </div>
            </div>
        );
    }

    private getMaxCellSize() {
        const game = this.props.game;
        const availableHeight = this.state.height - 40 - 80;
        const availableWidth = this.state.width - 40;
        const maxCellHeight = availableHeight / game.size.height;
        const maxCellWidth = availableWidth / game.size.width;
        return Math.floor(Math.min(maxCellHeight, maxCellWidth));
    }

    private cssClasses() {
        const game = this.props.game;
        const classes = [
            'gameboard',
        ];
        if (game.isCompleted()) classes.push('gameboard--complete');
        else classes.push('gameboard--incomplete');

        return classes.join(' ');
    }
}

export default GameBoard;