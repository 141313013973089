import {BulbState, GridSize} from "./types";
import GridLocation from "./logic/gridLocation";


export function cycle(oldBulb: BulbState): BulbState {
    switch (oldBulb) {
        case "bulb":
            return 'prohibited';
        case "empty":
            return "bulb";
        case "prohibited":
            return 'empty';
    }
}


export function allGridLocations(size: GridSize) {
    const ret = [];
    for (let i = 0; i < size.height; i += 1) {
        for (let j = 0; j < size.width; j += 1) {
            ret.push(new GridLocation(i, j));
        }
    }
    return ret;
}

export function inclusiveIntList(start: number, finish: number): number[] {
    const ret = [];
    for (let i = start; i <= finish; i += 1) {
        ret.push(i);
    }
    return ret;
}


export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}


